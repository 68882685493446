import React, { FC, useState } from 'react';
import {
  BooleanInput,
  DateTimeInput,
  NumberInput,
  SelectInput,
  SimpleForm,
  SimpleFormProps,
  TextInput,
  useRecordContext,
  useStore,
} from 'react-admin';
import {
  AirdropPointsMapping,
  AirdropQuestType,
  AirdropQuestTypeLabelMap,
} from '../../../Shared/AirdropQuest.types';

const AirdropQuestForm: FC<Omit<SimpleFormProps, 'children'>> = (props) => {
  const [copyData] = useStore('adQuestCopiedRow', []);
  const record = useRecordContext();
  const defaultValues =
    typeof copyData === 'string' ? JSON.parse(copyData) : copyData;
  const [selectedType, setSelectedType] = useState(
    AirdropQuestTypeLabelMap[defaultValues?.type]
  );
  const [questPoints, setQuestPoints] = useState<number>(
    Number(defaultValues.points)
  );

  const handleTypeChange = ({ target: { value } }: any): void => {
    setSelectedType(value);
    const points = (AirdropPointsMapping as any)[value] || 0;
    setQuestPoints(points);
  };

  const typeOptions = Object.values(AirdropQuestTypeLabelMap).map((type) => ({
    id: type,
    name: type,
  }));

  const isTriviaKnowledge =
    selectedType === AirdropQuestTypeLabelMap[AirdropQuestType.TriviaKnowledge];
  const isTriviaOrUserInput = [
    AirdropQuestTypeLabelMap[AirdropQuestType.TriviaKnowledge],
    AirdropQuestTypeLabelMap[AirdropQuestType.UserInput],
  ].includes(selectedType);
  const urlRequired = ![
    AirdropQuestTypeLabelMap[AirdropQuestType.TriviaKnowledge],
    AirdropQuestTypeLabelMap[AirdropQuestType.UserInput],
    AirdropQuestTypeLabelMap[AirdropQuestType.LoginStreak],
  ].includes(selectedType);
  const isLoginStreak =
    selectedType === AirdropQuestTypeLabelMap[AirdropQuestType.LoginStreak];

  const isTypeInOptions = typeOptions.some((opt) => opt.name === record?.type);

  return (
    <SimpleForm {...props}>
      {!record?.type || isTypeInOptions ? (
        <SelectInput
          source="type"
          label="Type"
          choices={typeOptions}
          onChange={handleTypeChange}
          defaultValue={selectedType}
          fullWidth
        />
      ) : (
        <TextInput
          source="type"
          label="Type"
          defaultValue={record?.type}
          disabled
          fullWidth
        />
      )}

      <TextInput
        source="name"
        label="Quest Name"
        fullWidth
        defaultValue={defaultValues.name ?? ''}
      />
      <TextInput
        source="description"
        label="Quest Description"
        fullWidth
        defaultValue={defaultValues.description ?? ''}
      />
      <NumberInput
        source="points"
        label="Quest Points"
        defaultValue={questPoints ?? ''}
      />
      <BooleanInput
        source="isActive"
        label="Active"
        defaultValue={defaultValues.isActive ?? true}
      />
      <TextInput
        source="linkedId"
        label="Linked ID"
        fullWidth
        defaultValue={defaultValues.linkedId ?? ''}
      />
      {urlRequired && (
        <TextInput
          source="url"
          label="URL"
          fullWidth
          defaultValue={defaultValues.url ?? ''}
        />
      )}

      {isTriviaOrUserInput && (
        <TextInput
          source="correctChoice"
          label="Correct Choice"
          fullWidth
          defaultValue={defaultValues.correctChoice ?? ''}
        />
      )}
      {isTriviaKnowledge && (
        <>
          <TextInput
            source="button1"
            label="Button 1"
            fullWidth
            defaultValue={defaultValues.button1 ?? ''}
          />
          <TextInput
            source="button2"
            label="Button 2"
            fullWidth
            defaultValue={defaultValues.button2 ?? ''}
          />
          <TextInput
            source="button3"
            label="Button 3"
            fullWidth
            defaultValue={defaultValues.button3 ?? ''}
          />
        </>
      )}
      {isLoginStreak && (
        <NumberInput
          source="maxDays"
          label="Max Days"
          defaultValue={defaultValues.maxDays ?? ''}
        />
      )}
      <DateTimeInput
        source="startAt"
        label="Start At"
        defaultValue={defaultValues.startAt ?? new Date().toISOString()}
      />
    </SimpleForm>
  );
};

export default AirdropQuestForm;
